
window.language = {
	short: "it",
	full: "it-IT",
	tld: "it"
},
window.date_format = "DD/MM/YYYY",
window.datetime_format = "DD/MM/YYYY HH:mm:SS",
window.strings = {
	destroy: "Elimina",
	destroy_selected: "Elimina selezionati",
	color: "Colore",
	duration: "Durata",
	confirm: {
		title: "Sei sicuro?",
  		commit: "Conferma",
  		cancel: "Annulla"
	},
	map: {
		redraw: "Ridisegna",
		redraw_description: "Click per ridisegnare",
		address: "Indirizzo"
	},
	companies: {
		parent: {
			are_you_sure: "L'azienda e tutte le sottostanti saranno spostate. Procedere?",
		}
	},
	machines: {
		battery: {
			empty: "Scarica",
			full: "Carica",
			charging: "In ricarica",
		},
		sweeper_side_brushes: JSON.parse('["Nessuna","Dx","Dx+Sx","Dx+Sx+III"]')
	},
	machine_models: {
		battery_types: JSON.parse('{"pb":"Piombo","pb1":"Piombo 1","gel":"Gel","gel1":"Gel 1","agm":"AGM/XFC"}')
	},
	positions: {
		time: "Data",
        latitude: "Latitudine",
        longitude: "Longitudine",
        altitude: "Altitudine",
        speed: "Velocità",
        satellites: "Satelliti"
	},
	daterangepicker: {
		applyLabel: "Conferma",
    	cancelLabel: "Annulla",
    	fromLabel: "Da",
    	toLabel: "A",
    	customRangeLabel: "Personalizzato",
		ranges: {
			today: "Oggi",
			yesterday: "Ieri",
			last_7_days: "Ultimi 7 giorni",
			last_30_days: "Ultimi 30 giorni",
			this_month: "Questo mese",
			last_month: "Mese scorso"
		}
	},
	filter_select_options: {
		"true": "Sì",
		"false": "No"
	},
	download: {
		async: {
			opened: "Generazione file…",
			downloading: "Scaricamento…",
			error: "Errore scaricamento"
		}
	}
}
